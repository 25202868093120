import {
  ChatBubbleOutlineOutlined,
  FavoriteBorderOutlined,
  FavoriteOutlined,
  ShareOutlined,
} from "@mui/icons-material";
import { Box, Divider, Button, IconButton, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import Friend from "components/Friend";
import WidgetWrapper from "components/WidgetWrapper";
import WidgetWrapperFlex from "components/WidgetWrapperFlex";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPost } from "state";
import moment from "moment";

const PostWidget = ({
  postId,
  postUserId,
  postUserName,
  description,
  createdAt,
  url,
  location,
  picturePath,
  userPicturePath,
  likes,
  comments,
}) => {
  const [isComments, setIsComments] = useState(false);
  const dispatch = useDispatch();
  const baseurl = process.env.REACT_APP_BASE_URL;
  const token = useSelector((state) => state.token);
  const loggedInUser = useSelector((state) => state.user);
  //const loggedInUserId = useSelector((state) => state.user._id);
  const isLiked = false; //Boolean(likes[loggedInUserId]);
  
  let likeCount = Object.keys(likes).length;

  const fakeLikes = [0,1,2];
  var randomFakeLike = fakeLikes[Math.floor(Math.random()*fakeLikes.length)];
  likeCount += randomFakeLike; //add fakeLike

  const { palette } = useTheme();
  const main = palette.neutral.main;
  const primary = palette.primary.main;

  const patchLike = async () => {
    const response = await fetch(`${baseurl}/posts/${postId}/like`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId: loggedInUser? loggedInUser._id : null }),
    });
    const updatedPost = await response.json();
    dispatch(setPost({ post: updatedPost }));
  };

  const buffer = -1; //force it to always show as X mins ago (this means we'll never see 'X seconds ago')
  const timeAgo = moment(createdAt).add(buffer,'minutes').fromNow(); //format('dddd, MMMM Do, YYYY h:mm:ss A')

  return (
    <WidgetWrapperFlex m="1rem 0">
      {picturePath && (
        <Box mr="1rem" overflow="hidden" width="200px">
          <a href={url} target="_blank">
            <img
              width="150px"
              height="auto"
              alt="post"
              style={{ borderRadius: "0.75rem" }}
              src={picturePath}
            />
          </a>       
        </Box>   
      )}
      <Box width="100%">
        <div>
          <Friend
            friendId={postUserId}
            name={postUserName}
            subtitle={timeAgo}
            userPicturePath={userPicturePath}
          />
          <Typography color={main} sx={{ mt: "1rem" }} dangerouslySetInnerHTML={{__html: description}}>
          </Typography>
        </div>
          
        <div>
          <FlexBetween mt="0.25rem">
            <FlexBetween gap="1rem">
              <FlexBetween gap="0.3rem">
                <Button href={url} target="_blank">
                  Get deal                  
                </Button>
              </FlexBetween>

              <FlexBetween gap="0.3rem">
                <IconButton onClick={patchLike}>
                  {isLiked ? (
                    <FavoriteOutlined sx={{ color: primary }} />
                  ) : (
                    <FavoriteBorderOutlined />
                  )}
                </IconButton>
                <Typography>{likeCount}</Typography>
              </FlexBetween>

              <FlexBetween gap="0.3rem">
                <IconButton onClick={() => setIsComments(!isComments)}>
                  <ChatBubbleOutlineOutlined />
                </IconButton>
                <Typography>{comments.length}</Typography>
              </FlexBetween>
            </FlexBetween>

            <IconButton>
              <ShareOutlined />
            </IconButton>
          </FlexBetween>
        </div>

        {isComments && (
          <Box mt="0.5rem">
            {comments.map((comment, i) => (
              <Box key={`${postUserName}-${i}`}>
                <Divider />
                <Typography sx={{ color: main, m: "0.5rem 0", pl: "1rem" }}>
                  {comment}
                </Typography>
              </Box>
            ))}
            <Divider />
          </Box>
        )}
      </Box>


    </WidgetWrapperFlex>
  );
};

export default PostWidget;
