import {
  ChatBubbleOutlineOutlined,
  FavoriteBorderOutlined,
  FavoriteOutlined,
  ShareOutlined,
} from "@mui/icons-material";
import { Box, Divider, Button, IconButton, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import Friend from "components/Friend";
import WidgetWrapper from "components/WidgetWrapper";
import WidgetWrapperFlex from "components/WidgetWrapperFlex";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBlogpost } from "state";
import moment from "moment";

const BlogpostWidget = ({
  postId,
  postUserId,
  postUserName,
  title,
  subtitle,
  body,
  createdAt,
  image,
  slug,
  userPicturePath,
  likes,
  comments,
}) => {
  const [isComments, setIsComments] = useState(false);
  const dispatch = useDispatch();
  const baseurl = process.env.REACT_APP_BASE_URL;
  const token = useSelector((state) => state.token);
  const loggedInUser = useSelector((state) => state.user);
  //const loggedInUserId = useSelector((state) => state.user._id);
  const isLiked = false; //Boolean(likes[loggedInUserId]);
  
  let likeCount = likes ? Object.keys(likes).length : 0;

  const fakeLikes = [2,3,4,5,6];
  var randomFakeLike = fakeLikes[Math.floor(Math.random()*fakeLikes.length)];
  likeCount += randomFakeLike; //add fakeLike

  const { palette } = useTheme();
  const main = palette.neutral.main;
  const primary = palette.primary.main;
  const medium = palette.neutral.medium;

  const patchLike = async () => {
    /* const response = await fetch(`${baseurl}/blogposts/${postId}/like`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId: loggedInUser? loggedInUser._id : null }),
    });
    const updatedBlogpost = await response.json();
    dispatch(setBlogpost({ blogpost: updatedBlogpost })); */
  };

  const buffer = -1; //force it to always show as X mins ago (this means we'll never see 'X seconds ago')
  const timeAgo = moment(createdAt).add(buffer,'minutes').fromNow(); //format('dddd, MMMM Do, YYYY h:mm:ss A')
  const blogimagePath = `${baseurl}/assets/images/blogposts/`; //`${baseurl}/api/assets/images/blogposts/`;

  const truncatedBody = truncateHtmlBody(body);
  return (
    <WidgetWrapperFlex m="1rem 0">
      {image && (
        <Box mr="1rem" overflow="hidden" width="200px">
          <a href={slug} target="_blank">
            <img
              width="150px"
              height="auto"
              alt="post"
              style={{ borderRadius: "0.75rem" }}
              src={blogimagePath + image}
            />
          </a>       
        </Box>   
      )}
      <Box width="100%">
        <div>
          {postUserId && (
            <Friend
              friendId={postUserId}
              name={postUserName}
              subtitle={timeAgo}
              userPicturePath={userPicturePath}
          /> )}
          <Typography
            color={main}
            variant="h5"
            fontWeight="500"
            sx={{
              mt: "1rem",
              "&:hover": {
                color: palette.primary.light,
                cursor: "pointer",
              },
            }}
          >
            {title}
          </Typography>
          <Typography color={medium} fontSize="0.75rem">
            {timeAgo}
          </Typography>
          <Typography color={main} sx={{ mt: "1rem" }} dangerouslySetInnerHTML={{__html: truncatedBody}}>
          </Typography>
        </div>
          
        <div>
          <FlexBetween mt="0.25rem">
            <FlexBetween gap="1rem">
              <FlexBetween gap="0.3rem">
                <Button href={slug} target="_blank">
                  Read more                  
                </Button>
              </FlexBetween>

              <FlexBetween gap="0.3rem">
                <IconButton onClick={patchLike}>
                  {isLiked ? (
                    <FavoriteOutlined sx={{ color: primary }} />
                  ) : (
                    <FavoriteBorderOutlined />
                  )}
                </IconButton>
                <Typography>{likeCount}</Typography>
              </FlexBetween>

              <FlexBetween gap="0.3rem">
                <IconButton onClick={() => setIsComments(!isComments)}>
                  <ChatBubbleOutlineOutlined />
                </IconButton>
                <Typography>{comments.length}</Typography>
              </FlexBetween>
            </FlexBetween>

            <IconButton>
              <ShareOutlined />
            </IconButton>
          </FlexBetween>
        </div>

        {isComments && (
          <Box mt="0.5rem">
            {comments.map((comment, i) => (
              <Box key={`${postUserName}-${i}`}>
                <Divider />
                <Typography sx={{ color: main, m: "0.5rem 0", pl: "1rem" }}>
                  {comment}
                </Typography>
              </Box>
            ))}
            <Divider />
          </Box>
        )}
      </Box>


    </WidgetWrapperFlex>
  );
};


const truncateHtmlBody = (body) => {
  if (!body) return body;
  const delim = '<p>';
  const parts = body.split(delim);
  if (parts.length < 4) return body;
  let output = [];
  const numberOfParagraphs = 3;
  for (var i = 0; i < parts.length; i++) {
    const part = parts[i];
    //console.log('part:',part);
    if (i > numberOfParagraphs) {
      break;
    }
    output.push(part);
  }

  let final = output.join(delim);
  const numberOfCharsToRemoveFromEnd = 20;
  final = final.substring(0, final.length - numberOfCharsToRemoveFromEnd);
  final += '...'
  return final;

}

export default BlogpostWidget;
