import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBlogposts } from "state";
import BlogpostWidget from "./BlogpostWidget";

const BlogpostsWidget = ({ userId, isProfile = false }) => {
  const dispatch = useDispatch();

  const blogposts = useSelector((state) => state.blogposts);
  const token = useSelector((state) => state.token);
  const baseurl = process.env.REACT_APP_BASE_URL;

  const getBlogposts = async () => {
    const response = await fetch(`${baseurl}/blogposts`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    dispatch(setBlogposts({ blogposts: data }));
  };
  /*
  const getUserBlogposts = async () => {
    if (!userId) { dispatch(setBlogposts({ blogposts: {} })); return; }
    const response = await fetch(
      `${baseurl}/blogposts/${userId}/blogposts`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();
    dispatch(setBlogposts({ blogposts: data }));
  };
  */
  useEffect(() => {
    getBlogposts();
    /*if (isProfile) {
      getUserBlogposts();
    } else {
      getBlogposts();
    }*/
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!blogposts || blogposts.error) {return null;}
  return (
    <>
      {blogposts.map(
        ({
          _id,
          userId,
          userName,
          firstName,
          lastName,
          title,
          subtitle,
          body,
          createdAt,
          image,
          slug,
          userPicturePath,
          likes,
          comments,
        }) => (
          <BlogpostWidget
            key={_id}
            postId={_id}
            postUserId={userId}
            postUserName={userName}
            title={title}
            subtitle={subtitle}
            body={body}
            createdAt={createdAt}
            image={image}
            slug={slug}
            userPicturePath={userPicturePath}
            likes={likes}
            comments={comments}
          />
        )
      )}
    </>
  );
};

export default BlogpostsWidget;
