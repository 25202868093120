import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import Navbar from "scenes/navbar";
import UserWidget from "scenes/widgets/UserWidget";
import MyPostWidget from "scenes/widgets/MyPostWidget";
import BlogpostsWidget from "scenes/widgets/BlogpostsWidget";
import PostsWidget from "scenes/widgets/PostsWidget";
import AdvertWidget from "scenes/widgets/AdvertWidget";
import FriendListWidget from "scenes/widgets/FriendListWidget";

const HomePage = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const { palette } = useTheme();
  const mediumMain = palette.neutral.mediumMain;
  const medium = palette.neutral.medium;
  const main = palette.neutral.main;

  //const { _id, picturePath } = useSelector((state) => state.user);
  const user = useSelector((state) => state.user);
  const _id = user ? user._id : null;
  const picturePath = user ? user.picturePath : "guest.jpg";

  return (
    <Box>
      <Navbar />
      <Box
        width="100%"
        padding="2rem 6%"
        display={isNonMobileScreens ? "flex" : "block"}
        gap="1.5rem"
        justifyContent="space-between"
      >
        {/*
        <Box flexBasis={isNonMobileScreens ? "26%" : undefined}>
          <UserWidget userId={_id} picturePath={picturePath} />
        </Box> 
        */}
        <Box
          flexBasis={isNonMobileScreens ? "68%" : undefined}
          mt={isNonMobileScreens ? undefined : "2rem"}
        >
          { /* <MyPostWidget picturePath={picturePath} /> */ }
          <Typography
            color={mediumMain}
            sx={{ "&:hover": { cursor: "pointer", color: medium } }}
          >
            RECENT BLOG POSTS
          </Typography>
          <BlogpostsWidget userId={_id} />
        </Box>
        {isNonMobileScreens && (
          <Box flexBasis="26%">
            <Typography
              color={mediumMain}
              sx={{ "&:hover": { cursor: "pointer", color: medium } }}
            >
              LATEST DEALS
            </Typography>
            <PostsWidget userId={_id} />
            {/*
            <AdvertWidget />
            <Box m="2rem 0" />
            <FriendListWidget userId={_id} />
            */}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default HomePage;
